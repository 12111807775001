export const data = [
    {
        title: "Our Services",
        data: [
            {
                name: "Pharmacovigilance Services"
            },
            {
                name: "Quality & Auditing"
            },
            {
                name: "Training & Coaching"
            },
        ]
    },
    {
        title: "Company",
        data: [
            {
                name: "About"
            },
            {
                name: "Contact us"
            },
        ]
    },
]
