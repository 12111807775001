import React from 'react'
import Tab1Img from '../../assets/images/tab1.png'
import Tab2Img from '../../assets/images/tab2.png'
import Tab3Img from '../../assets/images/tab3.png'

import './styles.scss'
import { NavLink } from 'react-router-dom';

const ServiceTabs = ({ tab }) => {
  const tabData = {
    tab1: {
      title: 'Pharmacovigilance Services',
      img: Tab1Img,
      data: [
        {
          name: "QPPV Office"
        },
        {
          name: "PSMF support"
        },
        {
          name: "Process improvements"
        },
        {
          name: "GVP consulting"
        },
      ]
    },
    tab2: {
      title: 'Quality & Auditing',
      img: Tab2Img,
      data: [
        {
          name: "GVP, CSV and GCP Audit services"
        },
        {
          name: "Inspection support"
        },
        {
          name: "Risk-based audit planning"
        },
        {
          name: "QMS set up and maintence support"
        },
        {
          name: "SOP Development"
        },
      ]
    },
    tab3: {
      title: 'Training & Coaching',
      img: Tab3Img,
      data: [
        {
          name: "Bespoke, in-house pharmacovigilance and quality training"
        },
        {
          name: "GVP Modules & FDA regulations made easy with practical implementation"
        },
        {
          name: "Coaching - QPPV, auditors, teams, medical writers"
        },
      ]
    },
  }


  return (
    <div className='service-tabs-wrapper'>
      <div className='left-side'>
        <span className='titleBlack tab-title'>{tabData[tab]?.title}</span>
        {
          tabData[tab]?.data?.map((item) => {
            return (
              <NavLink to="/about" className='tab-item-wrapper'>
                <span>{item.name}</span>
              </NavLink>
            )
          })
        }
      </div>
      <div className='right-side'>
        <img src={tabData[tab]?.img} alt='tab-thumbnail' />
      </div>
    </div>
  )
}

export default ServiceTabs
