import { BrowserRouter } from 'react-router-dom';
import Router from './Router/router';


const App = () => {
  return (
    <BrowserRouter>
      <Router>
      </Router>
    </BrowserRouter>
  );
}

export default App;
