import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ServiceTabs from '../../components/ServiceTabs/View';
import EmailForm from '../../components/Email/View';
import SectionVideo from '../../components/SectionVideo/View';
import './styles.scss';

const Home = () => {
  return (
    <div className="homepage-wrapper">
      {/* Section one start  */}
      <div className="section-one-wrapper">
        <div className="section-one-text-wrapper">
          <span className="titleBlack">
            Your partner for quality & pharmacovigilance
          </span>
          <hr className="horizontalLine"></hr>
          <span className="paragraphBlack">
            Established in 2008, NostraPharma is a leading Pharmacovigilance, quality and training
            consultancy committed to delivering excellence within the life sciences industry.
          </span>
        </div>
        <SectionVideo />
      </div>
      {/* Section one end  */}
      {/* Section Services start  */}
      <div className="services-wrapper">
        <span className="titleWhite">Services</span>
        {/* <span className="paragraphWhite service-paragraph">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </span> */}
      </div>
      {/* Section Services end  */}
      <div>
        <Tabs>
          <TabList>
            <Tab>Pharmacovigilance Services</Tab>
            <Tab>Quality & Auditing</Tab>
            <Tab>Training & Coaching</Tab>
          </TabList>
          <TabPanel>
            <ServiceTabs tab="tab1" />
          </TabPanel>
          <TabPanel>
            <ServiceTabs tab="tab2" />
          </TabPanel>
          <TabPanel>
            <ServiceTabs tab="tab3" />
          </TabPanel>
        </Tabs>
      </div>
      {/* Section why us start */}\
      <div className="why-us-root">
        <div className="why-us-wrapper">
          <span className="titleBlack">Why Us</span>
          <span className="paragraphBlack why-us-paragraph">
            “True quality is built through experience, enthusiasm and continuous refinement of the organisation and processes. Our team prides itself on these qualities delivering excellence and building a unified route to support your GxP lifecycle. We take great satisfaction in delivering a truly bespoke service guiding our partners in achieving high standards of quality; focusing not just on meeting regulatory requirements but building unique GxP eco-systems and processes that support improvements centred around core company values.”
          </span>
          <span className="why-us-signature">Natasa Mihajlovic ⎯ Founder</span>
        </div>
      </div>
      <EmailForm />
      {/* Section why us end  */}
    </div>
  );
};

export default Home;
