import React from 'react'
import HomepageVideo from '../../assets/videos/homepage-video.mp4'
import './styles.scss'

const SectionVideo = () => {
  return (
    <div className='section-video-wrapper'>
      <video className="masked-video" autoPlay muted loop>
        <source src={HomepageVideo} type="video/mp4" />
      </video>
    </div>
  )
}

export default SectionVideo
