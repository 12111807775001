import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import HomepageVideo from '../../assets/videos/homepage-video.mp4';
import MailSvg from '../../assets/images/mail.svg';
import LinkedInSvg from '../../assets/images/LinkedIn.svg';
import './styles.scss'

const EmailForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', email: '', message: '' };

    if (!formData.name || formData.name.length > 30) {
      newErrors.name = 'Name must be a string and less than 30 characters';
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Email must be a valid email address';
      isValid = false;
    }

    if (!formData.message) {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAIL_USER_ID
      ).then(() => {
      setFormData({
        name: '',
        email: '',
        message: ''
      })
      }).catch((err) => {
        console.error('FAILED...', err);
      });
    }
  };

    return (
      <div className='contact-us-wrapper'>
        <div className='section-video-wrapper'>
          <video className="masked-video" autoPlay muted loop>
            <source src={HomepageVideo} type="video/mp4" />
          </video>
        </div>
        <div className='right-side'>
          <span className='titleWhite'>Contact Us</span>
          <form onSubmit={handleSubmit} className='email-form'>
            <div className='row'>
              <div className='input-wrapper'>
                <label className='form-label' htmlFor="name">Name <span
                  className="required-label">(required)</span></label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className='form-input'
                />
                {errors.name && <p>{errors.name}</p>}
              </div>
              <div className='input-wrapper'>
                <label className='form-label' htmlFor="email">Email <span
                  className="required-label">(required)</span></label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className='form-input'
                />
                {errors.email && <p>{errors.email}</p>}
              </div>
            </div>
            <div className='input-wrapper'>
              <label className='form-label' htmlFor="message">Message <span className="required-label">(required)</span></label>
              <textarea
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                required
                className='form-input'
              />
              {errors.message && <p>{errors.message}</p>}
            </div>
            <button type="submit" className='form-button'>Send Message</button>
            <div className="contact-info">
              <a href="mailto:contact@nostrapharma.com" className="contact-info-item">
                <img src={MailSvg} alt="mail-svg"/>
                <span>contact@nostrapharma.com</span>
              </a>
              <div className="divider-vertical"/>
              <a href="https://www.linkedin.com/company/nostrapharma-ltd/" className="contact-info-item">
                <img src={LinkedInSvg} alt="linkedin-svg"/>
                <span>Visit our LinkedIn profile</span>
              </a>
            </div>
          </form>
        </div>
      </div>
    );
};

export default EmailForm;
