import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/View';
import Layout from '../pages/Layout';
import About from '../pages/About/View';

const Routing = () => {
    return (
        <Layout>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
            </Routes>
        </Layout>
    );
}

export default Routing;