import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Logo from '../../assets/images/Logo.svg'
import OpenIcon from '../../assets/images/drag_handle.svg'
import CloseIcon from '../../assets/images/close.svg'

import './styles.scss'

const Navbar = ({isOpen, toggleMenu}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='mobile-view'>
      <div className="navbar-wrapper">
        <img src={Logo} alt='Nostra pharma logo' />
        {isMobile ? (
          <div className="hamburger-menu">
            <img onClick={toggleMenu} src={isOpen ? CloseIcon : OpenIcon} alt='hamburger menu icon' />
          </div>
        ) : (
          <div className="navbar-routes-wrapper">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="mobile-menu">
          <Link to="/" onClick={toggleMenu}>Home</Link>
          <Link to="/about" onClick={toggleMenu}>About</Link>
        </div>
      )}
    </div>
  );
}

export default Navbar
