import React from 'react';

import EmailForm from '../../components/Email/View';

import CTOImage from '../../assets/images/Natasha.png';
import CTOImage2 from '../../assets/images/Gemma.png';
import CTOImage3 from '../../assets/images/Cover.png';
import HomepageVideo from '../../assets/videos/homepage-video.mp4';

import './styles.scss';

const About = () => {
  return (
    <div className="about-us-wrapper">
      <div className="banner-section">
        <div className="section-content">
          <span className="about-us-title">
            Your partner for quality & pharmacovigilance
          </span>
          <hr className="horizontalLine"></hr>
          <span className="paragraphWhite">
            Headquartered in the UK and established in 2008, our team has decades of experience and a proven track record of delivering complex projects ensuring that our clients' needs are met by establishing  long-term partnerships. Our aim is to consistently deliver high-quality PV consultancy, Quality and Training services that meet and exceed client expectations.
          </span>
        </div>
        <div className="section-video-wrapper">
          <video className="masked-video" autoPlay muted loop>
            <source src={HomepageVideo} type="video/mp4"/>
          </video>
        </div>
      </div>
      <div className="about-section-one-wrapper">
        <div className="cards-wrapper">
          <div className="card">
            <div className="horizontal-line"></div>
            <span className="paragraphBlack">
              We provide expert QPPV office support, PSMF set up and maintenance, PV Agreements set up, coordination and improvements, PV system set up and SOP writing.<br /><br />

              Within our quality services we provide GVP, GCP and CSV audits, risk-based audit planning, inspection readiness, mock -inspections, design, implementation, monitoring and review of the quality system, together with CAPA management and gap analysis.
            </span>
          </div>
          <div className="card">
            <div className="horizontal-line"></div>
            <span className="paragraphBlack">
              As part of our commitment and passion towards the industry we deliver training which is tailored to your needs. Our highly experienced team provides bespoke in -house pharmacovigilance and quality training including but not limited to; GVP Modules, Signal Management, Risk Management, PSUR/PBRERs, Inspection readiness, SOP writing, Data integrity, CAPA and root cause analysis and methodologies.
            </span>
          </div>
        </div>
      </div>
      <div className="about-section-one-wrapper-black">
        <div className="cards-wrapper">
          <div className="card">
            <span className="titleWhite subtitle">Short term projects:</span>
            <div className="horizontal-line"></div>
            <span className="paragraphWhite">
              &#x2022; PV and Quality Consultancy<br />
              &#x2022; Audit and CAPA support<br />
              &#x2022; Inspection readiness<br />
              &#x2022; PV support compliance & quality role<br />
              &#x2022; PV Project Manager<br />
              &#x2022; SOP writing<br />
              &#x2022; Gap analysis<br />
              &#x2022; Bespoke in-house training<br />
            </span>
          </div>
          <div className="card">
            <span className="titleWhite subtitle">Long-term projects: </span>
            <div className="horizontal-line"></div>
            <span className="paragraphWhite">
              &#x2022; QPPV office<br />
              &#x2022; UK QPPV role<br />
              &#x2022; PSMF maintenance<br />
              &#x2022; Long-term audit support<br />
              &#x2022; Medical writing (e.g. PBRER/CSRs)<br />
              &#x2022; Project management<br />
              &#x2022; CAPA implementation post-inspection <br />
            </span>
          </div>
        </div>
      </div>
      <div className="about-leaders-section">
        <span className="titleBlack title">Nostrapharma Team</span>
        <div className="cards-wrapper">
          <div className="card">
            <div className='row-section'>
              <img src={CTOImage} alt="cto"/>
              <div className='text-section'>
                <span className="paragraphBlack title">Natasa Mihajlovic</span>
                <span className="paragraphBlack paragraphRed">Founder, Managing Director </span>
                <span className="description">
                  Experienced QPPV, certified lead auditor and certified trainer with over 27 years’ experience in pharmaceutical consultancy and industry. Natasa also holds a MSc in pharmacovigilance and Diploma in Artificial Intelligence. During her consultancy career, she has worked with Roche, Bayer, Shire, GSK, BMS as well as several small and medium sized companies with headquarters in the USA, UK, France, India, Spain, Belgium, Italy and Japan. Natasa was involved in multiple regulatory pre-inspections and inspections (including EMA, FDA, Swedish, French, Irish, German, Croatian, Turkish, Hungarian Regulatory Authority and number of MHRA inspections). She has conducted over 290+ audits and has +700 days auditing experience of headquarters, affiliates, licensing partners, PV processes, computerized system validation and PV service providers worldwide. Main focus: remote and process PV audits (special interest in aRMM/PASS/signal management) and use of AI.
                </span>
              </div>
            </div>
            <div className='quote'>
              <span className='paragraphBlack'>
                “My love affair with PV started almost three decades ago and I still feel same excitement, motivation to improve, contribute and give to industry. Being part and witnessing the evolution of PV and technology makes me fully fortunate”.
              </span>
            </div>
          </div>
          <div className="card">
            <div className='row-section'>
              <img src={CTOImage2} alt="cto"/>
              <div className='text-section'>
                <span className="paragraphBlack title">Gemma Berry Jones</span>
                <span className="paragraphBlack paragraphRed">Director, Head of Quality Operations </span>
                <span className="description">
                  Has over 18 years' experience in Pharmacovigilance and Pharmaceutical work and completed an MSc in Pharmacovigilance from the University of Hertfordshire in 2013.  Gemma has worked with a wide range of Pharmaceutical companies helping to identify gaps in processes that allow for improvements in regulatory compliance.  Gemma has also supported a number of Health Authority inspections within Europe and North America.
                </span>
              </div>
            </div>
            <div className='quote'>
              <span className='paragraphBlack'>
                “We are lucky to work in an industry where we have the opportunity to significantly improve patients' lives, I feel grateful to have a role in helping to influence and support positive change.”
              </span>
            </div>
          </div>
          <div className="card">
            <div className='row-section'>
              <img src={CTOImage3} alt="cto"/>
              <div className='text-section'>
                <span className="paragraphBlack title">Masha Palos</span>
                <span className="paragraphBlack paragraphRed">Deputy Managing Director, Head of Operations</span>
                <span className="description">
                  Has over 18 years of service provider experience in leadership and business strategy roles across a variety of industries from market intelligence to medical research. Masha’s diverse experience puts her in a unique position to deliver clients with a truly bespoke service focusing on their needs first. Working closely with our experienced GxP team her skills enable her to truly meet client requirements in line with their core values.
                </span>
              </div>
            </div>
            <div className='quote'>
              <span className='paragraphBlack'>
                “PV is an industry like none I have ever worked in, full of committed hard working individuals passionate about quality. To support them we must not only meet their standard but surpass our own to help them reach their vision“
              </span>
            </div>
          </div>
        </div>
      </div>
      <EmailForm/>
    </div>
  );
};

export default About;
