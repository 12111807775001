import React, { useState } from 'react';

import Navbar from '../components/Navbar/View';
import Footer from '../components/Footer/View';

export default function Layout({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={isOpen ? 'layout open-nav' : 'layout'}>
      <Navbar toggleMenu={toggleMenu} isOpen={isOpen} />
      {children}
      <Footer />
    </div>
  );
}
